import { getApp, getApps, initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGIN_ID,
	appId: process.env.REACT_APP_FIREBASE_APPI_ID,
}

console.log('===', process.env.REACT_APP_FIREBASE_API_KEY)

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig)
const storage = getStorage(app)
export { app, storage }
