import React, { useEffect, useLayoutEffect, useState } from 'react'
import {
	Routes,
	Route,
	useNavigate,
	useParams,
	Navigate,
} from 'react-router-dom'
import './App.css'
import {
	AlertError,
	Dashboard,
	Home,
	Loader,
	Login,
	MusicPlayer,
	UserProfile,
} from './components'
import { useStateValue } from './Context/StateProvider'
import { motion, AnimatePresence } from 'framer-motion'
import Releases from './pages/Releases'
import SongsList from './components/SongsList'
import Dj_charts from './pages/dj-charts'
import Mash_up from './pages/mash-up'
import Top from './pages/Top'
import Russian_Charts from './pages/Russian-charts'
import Dj_Russian_charts from './pages/dj-russian-charts'
import Events from './pages/Events'
import SubStyle from './pages/subStyle'
import { API_URL } from './api/http/axios'
import { actionType } from './Context/reducer'
import { checkAuth } from './api'
import Wav from './pages/wav'
import Bandcamp from './pages/Bandcamp'
import Log from './pages/log'
import AdminDashboard from './pages/AdminDashboard'

function App() {
	const navigate = useNavigate()
	const [{ user, allSongs, song, isSongPlaying, miniPlayer }, dispatch] =
		useStateValue()
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		setIsLoading(true)
		if (!localStorage.getItem('token')) {
			navigate('/regError')
			setIsLoading(false)
		} else {
			checkAuth(localStorage.getItem('token'))
				.then(res => {
					dispatch({
						type: actionType.SET_USER,
						user: { name: res.data.username, role: res.data.role },
					})
					console.log('as')
					setIsLoading(false)
				})
				.catch(e => {
					navigate('/regError')
				})
		}
	}, [])

	return (
		<AnimatePresence>
			<div className='h-auto flex items-center justify-center min-w-[680px]'>
				{isLoading ? (
					<Loader />
				) : (
					<Routes>
						<Route
							path='/'
							element={<Navigate to='/Releases?page=1' replace />}
						/>
						<Route path='/Releases' element={<Releases />} />
						<Route path='/:token' element={<Log />} />
						<Route path='/dj-charts' element={<Dj_charts />} />
						<Route path='/mash-up' element={<Mash_up />} />
						<Route path='/top-100' element={<Top />} />
						<Route path='/russian-charts' element={<Russian_Charts />} />
						<Route path='/dj-russian-charts' element={<Dj_Russian_charts />} />
						<Route path='/wav' element={<Wav />} />
						<Route path='/bandcamp' element={<Bandcamp />} />

						<Route path='/regError' element={<AlertError />} />
						<Route path='/events' element={<Events />} />
						<Route path='/events/:subStyle' element={<SubStyle />} />

						<Route
							path='/Releases/:style'
							element={<SongsList category={'Releases'} />}
						/>
						<Route
							path='/dj-charts/:style'
							element={<SongsList category={'dj-charts'} />}
						/>
						<Route
							path='/mash-up/:style'
							element={<SongsList category={'mash-up'} />}
						/>
						<Route
							path='/top-100/:style'
							element={<SongsList category={'top-100'} />}
						/>
						<Route
							path='/events/pack/:style'
							element={<SongsList category={'events'} />}
						/>
						<Route
							path='/russian-charts/:style'
							element={<SongsList category={'russian-charts'} />}
						/>
						<Route
							path='/dj-russian-charts/:style'
							element={<SongsList category={'dj-russian-charts'} />}
						/>
						<Route
							path='/wav/:style'
							element={<SongsList category={'wav'} />}
						/>
						<Route
							path='/bandcamp/:style'
							element={<SongsList category={'bandcamp'} />}
						/>
						<Route path='/:category?search' />
						<Route path='/admin/dashboard' element={<AdminDashboard />} />
					</Routes>
				)}
				{isSongPlaying && (
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 50 }}
						className={`fixed min-w-[700px] h-26  inset-x-0 bottom-0  bg-cardOverlay drop-shadow-2xl backdrop-blur-md flex items-center justify-center`}
					>
						<MusicPlayer />
					</motion.div>
				)}
			</div>
		</AnimatePresence>
	)
}

export default App
