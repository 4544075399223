import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const Log = () => {
	const token = useParams()
	const navigate = useNavigate()

	useEffect(() => {
		localStorage.setItem('token', token.token)
		navigate('/')
	}, [])

	return <></>
}

export default Log
