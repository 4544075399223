import React, { useEffect, useState } from 'react'
import { useStateValue } from '../Context/StateProvider'
import { IoMdClose } from 'react-icons/io'
import { IoArrowRedo, IoArrowUndo, IoMusicalNote } from 'react-icons/io5'
import { motion } from 'framer-motion'

import AudioPlayer from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'
import { actionType } from '../Context/reducer'
import { MdPlaylistPlay } from 'react-icons/md'
import { getAllSongs } from '../api'
import { RiPlayListFill } from 'react-icons/ri'

const MusicPlayer = () => {
	const [isPlayList, setIsPlayList] = useState(false)
	const [{ allSongs, song, isSongPlaying, miniPlayer, songIndex }, dispatch] =
		useStateValue()

	const togglePlayer = () => {
		if (miniPlayer) {
			dispatch({
				type: actionType.SET_MINI_PLAYER,
				miniPlayer: false,
			})
		} else {
			dispatch({
				type: actionType.SET_MINI_PLAYER,
				miniPlayer: true,
			})
		}
	}

	useEffect(() => {
		if (!songIndex) {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: 0,
			})
		}
		if (!allSongs) {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: 0,
			})
		}
	}, [])

	const closeMusicPlayer = () => {
		if (isSongPlaying) {
			dispatch({
				type: actionType.SET_SONG_PLAYING,
				isSongPlaying: false,
			})
		}
	}
	// console.log(allSongs)
	const nextSong = () => {
		if (songIndex < allSongs.length - 1) {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: songIndex + 1,
			})
		} else {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: 0,
			})
		}
	}

	const previousSong = () => {
		if (songIndex > 0) {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: songIndex - 1,
			})
		} else {
			dispatch({
				type: actionType.SET_SONG_INDEX,
				songIndex: 0,
			})
		}
	}

	// console.log(song + 1)
	return (
		<div className='player w-full full flex items-center gap-3 overflow-hidden'>
			<div
				className={`player-nested w-full full justify-between items-center gap-3 p-4 ${
					miniPlayer ? 'absolute top-40' : 'flex relative'
				}`}
			>
				<div
					className={'player-block'}
					style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
				>
					<img
						src={allSongs ? allSongs[songIndex]?.imageURL : ''}
						className='mx-10 w-40 h-20 object-cover rounded-md'
						alt=''
					/>
					<div className='flex items-start flex-col'>
						<p className='text-xl text-headingColor font-semibold'>
							{`${allSongs ? allSongs[songIndex]?.name : ''}`}
							{/* <span className='text-base'>({song?.album})</span> */}
						</p>
						<p className='text-textColor'>
							{allSongs ? allSongs[songIndex]?.artist : ''}
							<span className='text-sm text-textColor font-semibold'>
								({allSongs ? allSongs[songIndex]?.category : ''})
							</span>
						</p>
					</div>
				</div>
				<div
					className={'player-block'}
					style={{ width: '50%', display: 'flex', flexDirection: 'row' }}
				>
					<div className='w-full'>
						<AudioPlayer
							src={allSongs ? allSongs[songIndex]?.songUrl : ''}
							onPlay={() => console.log('is playing')}
							autoPlay={true}
							onEnded={nextSong}
							showJumpControls={false}
							showSkipControls={true}
							onClickNext={nextSong}
							onClickPrevious={previousSong}
						/>
					</div>
					<div className='h-full flex items-center justify-center flex-col gap-3'>
						<motion.i whileTap={{ scale: 0.8 }} onClick={closeMusicPlayer}>
							<IoMdClose className='text-textColor hover:text-headingColor text-2xl cursor-pointer' />
						</motion.i>
						<motion.i whileTap={{ scale: 0.8 }} onClick={togglePlayer}>
							<IoArrowRedo className='text-textColor hover:text-headingColor text-2xl cursor-pointer' />
						</motion.i>
					</div>
				</div>
			</div>

			{miniPlayer && (
				<motion.div
					initial={{ opacity: 0, scale: 0.6 }}
					animate={{ opacity: 1, scale: 1 }}
					className='fixed right-2 bottom-2 '
				>
					<div className='w-40 h-40 rounded-full flex items-center justify-center  relative '>
						<div className='absolute inset-0 rounded-full bg-red-600 blur-xl animate-pulse'></div>
						<img
							onClick={togglePlayer}
							src={allSongs ? allSongs[songIndex]?.imageURL : ''}
							className='z-50 w-32 h-32 rounded-full object-cover cursor-pointer'
							alt=''
						/>
					</div>
				</motion.div>
			)}
		</div>
	)
}

export default MusicPlayer
