import { deleteObject, ref } from 'firebase/storage'
import { storage } from '../config/firebase.config'

export const filters = [
	{ id: 2, name: 'Soulful-Funk-Disco', value: 'Soulful-Funk-Disco' },
	{ id: 3, name: 'Afro House', value: 'Afro House' },
	{ id: 4, name: 'Bass House', value: 'Bass House' },
	{ id: 5, name: 'Bassline House', value: 'Bassline House' },
	{ id: 6, name: 'Breaks', value: 'Breaks' },
	{ id: 7, name: 'Chill Out', value: 'Chill Out' },
	{
		id: 8,
		name: '140 Deep Dubste-Grime',
		value: '140 - Deep Dubstep - Grime',
	},
	{ id: 9, name: 'Drum & Bass', value: 'Drum & Bass' },
	{ id: 10, name: 'Dubstep', value: 'Dubstep' },
	{ id: 11, name: 'Electronica', value: 'Electronica' },
	{ id: 12, name: 'Funky House', value: 'Funky House' },
	{ id: 13, name: 'Hard Dance-hardcore', value: 'Hard Dance - Hardcore' },
	{ id: 14, name: 'Hard Techno', value: 'Hard Techno' },
	{ id: 15, name: 'House', value: 'House' },
	{ id: 16, name: 'Indie Dance', value: 'Indie Dance' },
	{ id: 17, name: 'Melodic Techno', value: 'Melodic Techno' },
	{ id: 18, name: 'Minimal House', value: 'Minimal House' },
	{ id: 19, name: 'Nu-Disco-Disco', value: 'Nu-Disco - Disco' },
	{ id: 20, name: 'Organic House', value: 'Organic House - Downtempo' },
	{ id: 21, name: 'Progressive House', value: 'Progressive House' },
	{ id: 22, name: 'Psy-Trance', value: 'Psy-Trance' },
	{ id: 23, name: 'Trap', value: 'Trap' },
	{ id: 24, name: 'Soulful House', value: 'Soulful House' },
	{ id: 25, name: 'Tech House', value: 'Tech House' },
	{ id: 26, name: 'Techno', value: 'Techno' },
	{ id: 27, name: 'Trance', value: 'Trance' },
	{ id: 28, name: 'Deep House', value: 'Deep House' },
]
/**
 * Deep Dubstep
Afro House
Bass House
Bassline House
Breacks
Chill Out
Club House
Deep House
Drum & Bass
Dubstep
Electronica
Funky House
Hard Dance-hardcore
Hard Techno
Deep Dubstep
Afro House
Bass House
Bassline House
Breacks
Chill Out
Club House
Deep House
Drum & Bass
Dubstep
Electronica
Funky House
Hard Dance-hardcore
Hard Techno
DJ Charts
 */

export const filterByLanguage = [
	{ id: 1, name: 'Tamil', value: 'tamil' },
	{ id: 2, name: 'English', value: 'english' },
	{ id: 3, name: 'Malayalam', value: 'malayalam' },
	{ id: 4, name: 'Telungu', value: 'Telungu' },
	{ id: 5, name: 'Hindi', value: 'hindi' },
]

export const deleteAnObject = referenceUrl => {
	const deleteRef = ref(storage, referenceUrl)
	deleteObject(deleteRef)
		.then(() => {
			return true
		})
		.catch(error => {
			return false
		})
}
