import axios from 'axios'

export const API_URL = 'https://vip.soundmasters.pro/'

const $api = axios.create({
	withCredentials: true,
	bareURL: API_URL,
})

$api.interceptors.request.use(config => {
	config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`
	return config
})

export default $api
