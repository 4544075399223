export const initialState = {
	user: null,
	searchTerm: '',
	filterTerm: 'all',
	artists: null,
	artistFilter: null,
	languageFilter: null,
	allUsers: null,
	allSongs: null,
	allAlbums: null,
	albumFilter: null,
	song: 0,
	isSongPlaying: false,
	miniPlayer: false,
	pagination: 1,
	fetchLength: null,
	songIndex: null,
}
