import { useStateValue } from '../Context/StateProvider'
import { getAllSongs, getSongsCat, getStyle } from '../api'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { actionType } from '../Context/reducer'
import { motion } from 'framer-motion'
import { Header, Loader, SearchBar } from '../components'
import { NavLink, useSearchParams } from 'react-router-dom'
import { SimplePagination } from '../components/SongsList'
import { HomeSearchContainer } from './Releases'

const Bandcamp = () => {
	const [filteredSongs, setFilteredSongs] = useState(null)
	const [allStyles, setAllSyles] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams] = useSearchParams()
	const page = searchParams.get('page')

	const [{ pagination, allSongs }, dispatch] = useStateValue()

	useLayoutEffect(() => {
		dispatch({
			type: actionType.SET_PAGINATION,
			pagination: page,
		})
	}, [page])

	useEffect(() => {
		getStyle('Bandcamp & Vinyl', pagination).then(data => {
			setAllSyles(data.data.data)
			dispatch({
				type: actionType.SET_FETCH_LENGTH,
				fetchLength: data.data.length,
			})
		})
	}, [pagination, page])

	return (
		<div className='w-full h-auto flex flex-col items-center justify-center bg-primary'>
			<Header />
			<SearchBar category={'bandcamp'} />
			<div className='my-5'>
				{!allSongs ? <SimplePagination isSongList={21} /> : ''}
			</div>
			<div className='w-full h-auto flex items-center justify-evenly gap-4 flex-wrap p-4'>
				{isLoading ? <Loader /> : ''}
				{allSongs ? (
					<HomeSearchContainer musics={allSongs} />
				) : (
					<HomeSongContainer musics={allStyles} />
				)}
			</div>
		</div>
	)
}

export const HomeSongContainer = ({ musics }) => {
	const [{ isSongPlaying, song }, dispatch] = useStateValue()
	console.log(musics)

	return (
		<>
			{musics?.map((data, index) => (
				<NavLink to={`${data.name}?page=1`}>
					<motion.div
						key={data._id}
						whileTap={{ scale: 0.8 }}
						initial={{ opacity: 0, translateX: -50 }}
						animate={{ opacity: 1, translateX: 0 }}
						transition={{ duration: 0.3, delay: index * 0.1 }}
						className='relative w-40 min-w-210 px-2 py-4 cursor-pointer hover:shadow-xl hover:bg-card bg-gray-100 shadow-md rounded-lg flex flex-col items-center'
					>
						<div className='w-40 min-w-[160px] h-40 min-h-[160px] rounded-lg drop-shadow-lg relative overflow-hidden'>
							<motion.img
								whileHover={{ scale: 1.05 }}
								src={data.imageURL}
								alt=''
								className=' w-full h-full rounded-lg object-cover'
							/>
						</div>

						<p className='text-base text-headingColor font-semibold my-2'>
							{data.name}
							<span className='block text-sm text-gray-400 my-1'>
								{data.artist}
							</span>
						</p>
					</motion.div>
				</NavLink>
			))}
		</>
	)
}

export default Bandcamp
