import React, { useEffect, useState } from 'react'
import { IoSearch } from 'react-icons/io5'
import { actionType } from '../Context/reducer'
import { useStateValue } from '../Context/StateProvider'
import { Button } from '@material-tailwind/react'
import { checkAuth, searchFetch } from '../api'
import { NavLink, useNavigate } from 'react-router-dom'

const SearchBar = ({ category }) => {
	const [{ searchTerm }, dispatch] = useStateValue()
	const [search, setSearch] = useState()
	const navigate = useNavigate()

	const setSearchTerm = value => {
		dispatch({
			type: actionType.SET_SEARCH_TERM,
			searchTerm: value,
		})
	}

	useEffect(() => {
		if (searchTerm) {
			searchFetch(searchTerm, category).then(data => {
				dispatch({
					type: actionType.SET_ALL_SONGS,
					allSongs: data.data.data,
				})
			})
		} else {
			dispatch({
				type: actionType.SET_ALL_SONGS,
				allSongs: null,
			})
		}
	}, [search])

	useEffect(() => {
		if (!localStorage.getItem('token')) {
			navigate('/regError')
		} else {
			checkAuth(localStorage.getItem('token'))
				.then(res => {
					dispatch({
						type: actionType.SET_USER,
						user: { name: res.data.username, role: res.data.role },
					})
				})
				.catch(e => {
					navigate('/regError')
				})
		}
	}, [])

	const routesList = {
		Releases: 'Releases',
		'dj-charts': 'dj-charts',
		'mash-up': 'mash-up',
		// prettier-ignore
		'top-100': 'top-100',
		'russian-charts': 'russian-charts',
		'dj-russian-charts': 'dj-russian-charts',
		wav: 'wav',
		bandcamp: 'bandcamp',
		events: 'events',
	}

	return (
		<div className='w-full my-4 h-16 bg-card flex items-center justify-center'>
			<div className='w-full gap-4 p-4 md:w-2/3 bg-primary shadow-xl mt-12 rounded-md flex items-center'>
				<IoSearch className='text-2xl text-textColor' />
				<input
					type='text'
					value={searchTerm}
					className='w-full h-full bg-transparent text-lg text-textColor  border-none outline-none '
					placeholder='Search here ....'
					onChange={e => setSearchTerm(e.target.value)}
				/>
				<Button
					onClick={() => setSearch(searchTerm)}
					className='text-textColor'
				>
					<NavLink to={`/${routesList[category]}?search=${searchTerm}`}>
						Поиск
					</NavLink>
				</Button>
			</div>
		</div>
	)
}

export default SearchBar
