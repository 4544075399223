import { useStateValue } from '../Context/StateProvider'
import {
	deleteStyle,
	getAllSongs,
	getSongsCat,
	getSongsStyle,
	getStyle,
} from '../api'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { actionType } from '../Context/reducer'
import { motion } from 'framer-motion'
import { Header, Loader, SearchBar } from '../components'
import {
	NavLink,
	useNavigate,
	useParams,
	useSearchParams,
} from 'react-router-dom'

import { Button, IconButton, Typography } from '@material-tailwind/react'
import { IoDownloadOutline, IoDownloadSharp } from 'react-icons/io5'

const SongsList = ({ category }) => {
	const [allSongsFetch, setAllSongs] = useState(null)
	const [{ pagination, fetchLength, user, allSongs }, dispatch] =
		useStateValue()
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams] = useSearchParams()
	const page = searchParams.get('page')
	const [open, setOpen] = useState(false)

	const handleOpen = () => setOpen(!open)

	const handleDelStyle = async (data, category) => {
		console.log(category)
		deleteStyle(data, category).then(res => {
			console.log(res)
		})
	}

	const params = useParams()

	useLayoutEffect(() => {
		dispatch({
			type: actionType.SET_PAGINATION,
			pagination: page,
		})
	}, [page])

	useEffect(() => {
		console.log(category)
		setIsLoading(true)
		if (params.style) {
			setIsLoading(true)
			getSongsStyle(params.style, pagination, category).then(data => {
				dispatch({
					type: actionType.SET_FETCH_LENGTH,
					fetchLength: data.data.length,
				})
				setAllSongs(data.data.data)
				dispatch({
					type: actionType.SET_ALL_SONGS,
					allSongs: data.data.data,
				})
				return setIsLoading(false)
			})
		}
		dispatch({
			type: actionType.SET_SONG_INDEX,
			songIndex: 0,
		})
		if (allSongs) {
			return setAllSongs(allSongs)
		}
	}, [pagination, params.style])

	return (
		<div className='w-full h-auto flex flex-col items-center justify-center bg-primary'>
			<Header />
			<SearchBar category={category} />
			<div
				className='song_list w-full px-10 h-auto flex items-center justify-evenly gap-4 flex-wrap p-4 '
				style={{ marginBottom: '120px' }}
			>
				{isLoading ? <Loader /> : ''}
				{user?.role === 'ADMIN' ? (
					<Button onClick={handleOpen} color='red'>
						Del
					</Button>
				) : (
					''
				)}
				{open ? (
					<div className='fixed top-32 bg-white w-[30%] h-[20%] flex flex-col items-center justify-center z-10'>
						Удалить весь стиль? <br />
						{params.style}
						<div className='flex flex-row mt-2'>
							<Button onClick={handleOpen} className='mx-5' color='green'>
								Назад
							</Button>
							<Button
								color='red'
								onClick={() => handleDelStyle(params.style, category)}
							>
								Удалить
							</Button>
						</div>
					</div>
				) : (
					''
				)}
				<HomeSongContainer musics={allSongsFetch} />
			</div>
		</div>
	)
}

export const HomeSongContainer = ({ musics }) => {
	const [{ isSongPlaying, song, songIndex, user }, dispatch] = useStateValue()
	const [isActive, setIsActive] = useState()

	const addSongToContext = (data, index) => {
		setIsActive(index)

		if (!isSongPlaying) {
			dispatch({
				type: actionType.SET_SONG_PLAYING,
				isSongPlaying: true,
			})
		}
		if (song !== data) {
			dispatch({
				type: actionType.SET_SONG,
				song: data,
			})
		}
		dispatch({
			type: actionType.SET_SONG_INDEX,
			songIndex: index,
		})
	}

	return (
		<>
			<SimplePagination isSongList={50} />
			{musics?.map((data, index) => (
				<motion.div
					key={data._id}
					whileTap={{ scale: 0.8 }}
					initial={{ opacity: 0, translateX: -50 }}
					animate={{ opacity: 1, translateX: 0 }}
					transition={{ duration: 0.3, delay: index * 0.1 }}
					onClick={() => addSongToContext(data, index)}
					style={
						index === songIndex
							? {
									backgroundColor: 'rgb(246 222 222)',
									transition: 'ease-in-out .5s',
									boxShadow: '#f983836b 0px 0px 10px 0px',
							  }
							: { transition: 'ease-in-out .5s' }
					}
					className='relative w-full min-w-60 px-2 py-4 cursor-pointer hover:shadow-xl hover:bg-card bg-gray-100 shadow-md rounded-lg flex flex-row items-center'
				>
					<div className='w-20 min-w-[60px] h-20 min-h-[60px] rounded-lg drop-shadow-lg relative overflow-hidden'>
						<motion.img
							whileHover={{ scale: 1.05 }}
							src={data.imageURL}
							alt=''
							className=' w-full h-full rounded-lg object-cover'
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '90%',
							marginLeft: '25px',
						}}
					>
						<p className='text-base text-headingColor font-semibold my-2'>
							{data.name}
							<span className='block text-sm text-gray-400 my-1'>
								{data.artist}
							</span>
						</p>
						<a
							download
							href={data.songUrl}
							className='text-base text-headingColor font-semibold my-2'
						>
							<span className='text-gray-400'>
								{data.createdAt.split('T')[0]}
							</span>
							<span className='block text-sm flex flex-row justify-around text-gray-400 my-1'>
								Скачать{' '}
								<IoDownloadOutline className='text-gray-400  hover:text-headingColor text-xl cursor-pointer' />
							</span>
						</a>
					</div>
				</motion.div>
			))}
		</>
	)
}

export default SongsList

export function SimplePagination({ isSongList }) {
	const [active, setActive] = useState(1)
	const [{ fetchLength }, dispatch] = useStateValue()
	const [params] = useSearchParams()
	const page = params.get('page')
	const navigate = useNavigate()

	const next = () => {
		if (
			page === Math.ceil(fetchLength / isSongList) ||
			Math.ceil(fetchLength / isSongList) < 1
		)
			return
		dispatch({
			type: actionType.SET_PAGINATION,
			pagination: +page + 1,
		})
		navigate(`?page=${+page + 1}`)

		setActive(page + 1)
	}

	const prev = () => {
		if (active === 1) return
		dispatch({
			type: actionType.SET_PAGINATION,
			pagination: +page - 1,
		})
		navigate(`?page=${page - 1}`)
		setActive(page - 1)
	}

	return (
		<div className='flex items-center gap-8'>
			<IconButton
				size='sm'
				variant='outlined'
				onClick={prev}
				disabled={+page === 1}
			>
				prev
			</IconButton>
			<Typography color='gray' className='font-normal'>
				Page <strong className='text-gray-900'>{page}</strong> of
				<strong className='text-gray-900'>
					{Math.ceil(fetchLength / isSongList) <= 1
						? 1
						: Math.ceil(fetchLength / isSongList)}
				</strong>
			</Typography>
			<IconButton
				size='sm'
				variant='outlined'
				onClick={next}
				disabled={+page === Math.ceil(fetchLength / isSongList)}
			>
				next
			</IconButton>
		</div>
	)
}
