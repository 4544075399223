import React, { useState } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { filters } from '../../utils/supportfunctions'
import { useStateValue } from '../../Context/StateProvider'
import { actionType } from '../../Context/reducer'
import { Link, NavLink } from 'react-router-dom'

const ReleasesDropDown = () => {
	return (
		<div className='flex h-[100%] justify-center px-3'>
			<FlyoutLink href='#' FlyoutContent={PricingContent}>
				Releases
			</FlyoutLink>
		</div>
	)
}

const FlyoutLink = ({ children, href, FlyoutContent }) => {
	const [open, setOpen] = useState(false)

	const showFlyout = FlyoutContent && open

	return (
		<div
			onMouseEnter={() => setOpen(true)}
			onMouseLeave={() => setOpen(false)}
			className='relative w-fit h-fit'
		>
			<a
				href={href}
				className='relative font-normal text-lg text-textColor hover:text-headingColor duration-100 transition-all ease-in-out'
			>
				{children}
				<span
					style={{
						transform: showFlyout ? 'scaleX(1)' : 'scaleX(0)',
					}}
					className='absolute -bottom-2 -left-2 -right-2 h-1 origin-left scale-x-0 rounded-full bg-indigo-300 transition-transform duration-300 ease-out'
				/>
			</a>
			<AnimatePresence>
				{showFlyout && (
					<motion.div
						initial={{ opacity: 0, y: 15 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 15 }}
						style={{ translateX: '-25%' }}
						transition={{ duration: 0.3, ease: 'easeOut' }}
						className='absolute z-10 left-1/2 top-12 bg-white text-black'
					>
						<div className='absolute -top-6 left-0 right-0 h-6 bg-transparent' />
						<div className='absolute left-1/4 top-0 h-4 w-4 -translate-x-1/2 -translate-y-1/2 rotate-45 bg-white' />
						<FlyoutContent />
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	)
}

const PricingContent = () => {
	const [{ filterTerm, artists, allAlbums }, dispatch] = useStateValue()
	const [hover, setHover] = useState(false)

	const updateFilter = value => {
		dispatch({
			type: actionType.SET_FILTER_TERM,
			filterTerm: value,
		})
	}
	return (
		<div className='w-full bg-white p-6 shadow-xl' style={{ height: '24rem' }}>
			<div className='h-full flex flex-wrap gap-16 z-10'>
				<div className='flex flex-wrap' style={{ width: '32rem' }}>
					{filters?.map(data => (
						<NavLink to={`/Releases/${data.value}?page=1`}>
							<p
								onMouseEnter={() => setHover(true)}
								onMouseLeave={() => setHover(false)}
								key={data.id}
								style={{
									textShadow: 'none',
									verticalAlign: 'top',
									color: hover ? 'black' : 'initial',
								}}
								className={` ${
									data.value === filterTerm ? 'font-semibold' : 'font-normal'
								} flex cursor-pointer mb-1 h-5 w-32 mx-5 block text-sm text-neutral-400`}
							>
								{data.name}
							</p>
						</NavLink>
					))}
				</div>
			</div>
		</div>
	)
}

export default ReleasesDropDown
