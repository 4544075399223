export const isActiveStyles =
	'text-lg text-headingColor font-semibold important hover:text-headingColor duration-100 transition-all ease-in-out'
export const isNotActiveStyles =
	'text-lg text-textColor hover:text-headingColor duration-100 transition-all ease-in-out'

export const bgColors = [
	'#E9E2FF',
	'#FAE2FF',
	'#FFE2E6',
	'#E2FFE9',
	'#E2F4FF',
	'#FFFFE2',
]
