import axios from 'axios'

const baseURL = 'https://vip.soundmasters.pro/'

export const validateUser = async token => {
	try {
		const res = await axios.get(`${baseURL}api/users/login`, {
			headers: {
				Authorization: 'Bearer ' + token,
			},
		})
		return res.data
	} catch (error) {
		return null
	}
}

export const getAllArtist = async () => {
	try {
		const res = await axios.get(`${baseURL}api/artists/getAll`)
		return res.data
	} catch (error) {
		return null
	}
}

export const getAllUsers = async () => {
	try {
		const res = await axios.get(`${baseURL}api/users/getUsers`)
		return res.data
	} catch (error) {
		return null
	}
}

export const removeUser = async userId => {
	try {
		const res = axios.delete(`${baseURL}api/users/delete/${userId}`)
		return res
	} catch (error) {
		return null
	}
}

export const getAllSongs = async () => {
	try {
		const res = await axios.get(`${baseURL}api/songs/getAll`)
		return res.data
	} catch (error) {
		return null
	}
}

export const getAllAlbums = async () => {
	try {
		const res = await axios.get(`${baseURL}api/albums/getAll`)
		return res.data
	} catch (error) {
		return null
	}
}

export const changingUserRole = async (userId, role) => {
	try {
		const res = axios.put(`${baseURL}api/users/updateRole/${userId}`, {
			data: { role: role },
		})
		return res
	} catch (error) {
		return null
	}
}

export const saveNewArtist = async data => {
	try {
		const res = axios.post(`${baseURL}api/artists/save`, { ...data })
		return (await res).data.artist
	} catch (error) {
		return null
	}
}

export const saveNewAlbum = async data => {
	try {
		const res = axios.post(`${baseURL}api/albums/save`, { ...data })
		return (await res).data.album
	} catch (error) {
		return null
	}
}

export const saveNewSong = async data => {
	try {
		const res = axios.post(`${baseURL}api/songs/save`, { ...data })
		return (await res).data.song
	} catch (error) {
		return null
	}
}

export const deleteSongById = async id => {
	try {
		const res = axios.delete(`${baseURL}api/songs/delete/${id}`)
		return res
	} catch (error) {
		return null
	}
}

export const getSongsCat = async category => {
	try {
		const res = axios.get(`${baseURL}api/songs/getSongsCat/${category}`)
		return res
	} catch (e) {
		return null
	}
}

export const getStyle = async (style, page) => {
	try {
		const res = axios.get(`${baseURL}api/songs/getStyle/${style}?page=${page}`)

		return res
	} catch (e) {
		return null
	}
}

export const getSongsStyle = async (style, page, category) => {
	try {
		const res = axios.get(
			`${baseURL}api/songs/getSongsStyle/${style}?page=${page}&category=${category}`
		)
		return res
	} catch (e) {
		return null
	}
}

export const getSubStyle = async subStyle => {
	try {
		const res = axios.get(`${baseURL}api/songs/getSubStyle/${subStyle}`)
		return res
	} catch (e) {
		return null
	}
}

export const checkAuth = async token => {
	try {
		const res = axios.post(`${baseURL}api/users/token`, {
			token: token,
		})
		return res
	} catch (e) {
		return null
	}
}

export const searchFetch = async (searchTerm, category) => {
	try {
		const res = axios.post(`${baseURL}api/songs/search/?category=${category}`, {
			searchTerm,
		})
		return res
	} catch (e) {}
}

export const refreshCategory = async category => {
	try {
		const list = {
			Releases: '01',
			'DJ Charts & Albums': '02',
			'Mash-Up': '03',
			'Top 100': '04',
			Мероприятия: '05',
			'Russian Charts': '06',
			'DJ Russian Charts': '07',
			Wav: '08',
			'Bandcamp & Vinyl': '09',
		}
		const res = axios.get(`${baseURL}api/songs/refresh/songs/${list[category]}`)
		return res
	} catch (e) {
		console.log(e)
	}
}
//check/dubs

export const checkDups = async category => {
	try {
		const res = axios.post(`${baseURL}api/songs/check/dubs`, {
			collection: category,
		})
		return res
	} catch (e) {
		console.log(e)
	}
}

export const killDups = async category => {
	try {
		const res = axios.post(`${baseURL}api/songs/kill/dubs`, {
			collection: category,
		})
		return res
	} catch (e) {
		console.log(e)
	}
}

export const deleteStyle = async (style, category) => {
	try {
		console.log(category)
		const res = axios.delete(
			`${baseURL}api/songs/delete/style/${style}?category=${category}`
		)
		return res
	} catch (e) {
		console.log(e)
	}
}
