import { useStateValue } from '../Context/StateProvider'
import { getAllSongs, getSongsCat, getStyle } from '../api'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { actionType } from '../Context/reducer'
import { motion } from 'framer-motion'
import { Header, Loader, SearchBar } from '../components'
import { NavLink, useSearchParams } from 'react-router-dom'
import SongsList, { SimplePagination } from '../components/SongsList'
import { Button } from '@material-tailwind/react'

const Releases = () => {
	const [filteredSongs, setFilteredSongs] = useState(null)
	const [allStyles, setAllSyles] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	const [searchParams] = useSearchParams()
	const page = searchParams.get('page')
	const search = searchParams.get('search')

	useLayoutEffect(() => {
		dispatch({
			type: actionType.SET_PAGINATION,
			pagination: page,
		})
		if (!search) {
			dispatch({
				type: actionType.SET_ALL_SONGS,
				allSongs: null,
			})
		}
	}, [page])

	const [{ pagination, allSongs, fetchLength }, dispatch] = useStateValue()
	useEffect(() => {
		getStyle('Releases', pagination).then(data => {
			setAllSyles(data.data.data)
			dispatch({
				type: actionType.SET_FETCH_LENGTH,
				fetchLength: data.data.length,
			})
		})
		if (!allSongs) {
			dispatch({
				type: actionType.SET_SONG_PLAYING,
				isSongPlaying: false,
			})
		}
	}, [pagination, fetchLength, search])

	return (
		<div className='w-full h-auto flex flex-col items-center justify-center bg-primary'>
			<Header />
			<SearchBar category={'Releases'} />
			<div className='my-5'>
				{!allSongs ? <SimplePagination isSongList={21} /> : ''}
			</div>
			<div className='w-full h-auto flex items-center justify-evenly gap-4 flex-wrap p-4'>
				{isLoading ? <Loader /> : ''}
				{allSongs ? (
					<HomeSearchContainer musics={allSongs} />
				) : (
					<HomeSongContainer musics={allStyles} />
				)}
			</div>
		</div>
	)
}

export const HomeSongContainer = ({ musics }) => {
	const [{ isSongPlaying, song, user }, dispatch] = useStateValue()

	return (
		<>
			{musics?.map((data, index) => (
				<>
					<NavLink to={`${data.name}?page=1`}>
						<motion.div
							key={data._id}
							whileTap={{ scale: 0.8 }}
							initial={{ opacity: 0, translateX: -50 }}
							animate={{ opacity: 1, translateX: 0 }}
							transition={{ duration: 0.3, delay: index * 0.1 }}
							className='relative w-40 min-w-210 px-2 py-4 cursor-pointer hover:shadow-xl hover:bg-card bg-gray-100 shadow-md rounded-lg flex flex-col items-center'
						>
							<div className='w-40 min-w-[160px] h-40 min-h-[160px] rounded-lg drop-shadow-lg relative overflow-hidden'>
								<motion.img
									whileHover={{ scale: 1.05 }}
									src={data.imageURL}
									alt=''
									className=' w-full h-full rounded-lg object-cover'
								/>
							</div>

							<p className='text-base text-headingColor font-semibold my-2'>
								{data.name}
								<span className='block text-sm text-gray-400 my-1'>
									{data.artist}
								</span>
							</p>
						</motion.div>
					</NavLink>
				</>
			))}
		</>
	)
}

export default Releases

export const HomeSearchContainer = ({ musics }) => {
	const [{ isSongPlaying, song, songIndex }, dispatch] = useStateValue()
	const [isActive, setIsActive] = useState()

	const addSongToContext = (data, index) => {
		setIsActive(data._id)

		if (!isSongPlaying) {
			dispatch({
				type: actionType.SET_SONG_PLAYING,
				isSongPlaying: true,
			})
		}
		if (song !== data) {
			dispatch({
				type: actionType.SET_SONG,
				song: data,
			})
		}
		dispatch({
			type: actionType.SET_SONG_INDEX,
			songIndex: index,
		})
	}

	return (
		<>
			{musics?.map((data, index) => (
				<motion.div
					key={data._id}
					whileTap={{ scale: 0.8 }}
					initial={{ opacity: 0, translateX: -50 }}
					animate={{ opacity: 1, translateX: 0 }}
					transition={{ duration: 0.3, delay: index * 0.1 }}
					onClick={() => addSongToContext(data, index)}
					style={
						index === songIndex
							? {
									backgroundColor: 'rgb(246 222 222)',
									transition: 'ease-in-out .5s',
									boxShadow: '#f983836b 0px 0px 10px 0px',
							  }
							: { transition: 'ease-in-out .5s' }
					}
					className='relative w-full min-w-60 px-2 py-4 cursor-pointer hover:shadow-xl hover:bg-card bg-gray-100 shadow-md rounded-lg flex flex-row items-center'
				>
					<div className='w-20 min-w-[60px] h-20 min-h-[60px] rounded-lg drop-shadow-lg relative overflow-hidden'>
						<motion.img
							whileHover={{ scale: 1.05 }}
							src={data.imageURL}
							alt=''
							className=' w-full h-full rounded-lg object-cover'
						/>
					</div>

					<div
						style={{
							display: 'flex',
							justifyContent: 'space-between',
							width: '90%',
							marginLeft: '25px',
						}}
					>
						<p className='text-base text-headingColor font-semibold my-2'>
							{data.name}
							<span className='block text-sm text-gray-400 my-1'>
								{data.artist}
							</span>
						</p>
						<a
							href={data.songUrl}
							target='_blank'
							className='text-base text-headingColor font-semibold my-2'
						>
							<span className='text-gray-400'>
								{data.createdAt.split('T')[0]}
							</span>
							<span className='block text-sm text-gray-400 my-1'>Скачать </span>
						</a>
					</div>
				</motion.div>
			))}
		</>
	)
}
