import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Logo } from '../assets/img'
import { useStateValue } from '../Context/StateProvider'
import { isActiveStyles, isNotActiveStyles } from '../utils/styles'
import { getAuth } from 'firebase/auth'
import { app } from '../config/firebase.config'
import { motion } from 'framer-motion'

import { FaCrown } from 'react-icons/fa'
import ReleasesDropDown from './assets/DropDown'

const Header = () => {
	const navigate = useNavigate()
	const [{ user }, dispatch] = useStateValue()

	const [isMenu, setIsMenu] = useState(false)

	const logout = () => {
		const firebaseAuth = getAuth(app)
		firebaseAuth
			.signOut()
			.then(() => {
				window.localStorage.setItem('auth', 'false')
			})
			.catch(e => console.log(e))
		navigate('/login', { replace: true })
	}

	return (
		<header className='flex items-center w-full p-4 md:py-2 md:px-6'>
			<a href={'https://soundmasters.pro/'}>
				<img src={Logo} className='w-16' alt='' />
			</a>

			<ul className='flex items-center flex-wrap justify-start ml-7'>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg">
          <NavLink to={'/Releases?page=1'} ><ReleasesDropDown className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}/></NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/dj-charts?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>DJ Charts & Albums</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/mash-up?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Mash-Up</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/top-100?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Top 100</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/events?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Мероприятия</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/russian-charts?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Russian Charts</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/dj-russian-charts?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>DJ Russian Charts</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/wav?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Wav</NavLink></li>
				{/* prettier-ignore */}
				<li className="mx-5 text-lg"><NavLink to={'/bandcamp?page=1'} className={({isActive}) => isActive ? isActiveStyles : isNotActiveStyles}>Bandcamp & Vinyl</NavLink></li>
			</ul>

			<div className='flex items-center ml-auto cursor-pointer gap-2 relative'>
				<div className='flex flex-col'>
					<p className='text-textColor text-lg hover:text-headingColor font-semibold'>
						{user?.name}
					</p>
					<p className='flex items-center gap-2 text-xs text-gray-500 font-normal'>
						{user?.role === 'ADMIN' ? (
							<NavLink to={'/admin/dashboard'}>{user?.role}</NavLink>
						) : (
							'Premium Member.'
						)}
						<FaCrown className='text-xm -ml-1 text-yellow-500' />{' '}
					</p>
				</div>

				{isMenu && (
					<motion.div
						initial={{ opacity: 0, y: 50 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, y: 50 }}
						className='absolute z-10 top-12 right-0 w-275 p-4 gap-4 bg-card shadow-lg rounded-lg backdrop-blur-sm flex flex-col'
					>
						<NavLink to={'/userProfile'}>
							<p className='text-base text-textColor hover:font-semibold duration-150 transition-all ease-in-out'>
								Profile
							</p>
						</NavLink>
						<p className='text-base text-textColor hover:font-semibold duration-150 transition-all ease-in-out'>
							My Favourites
						</p>
						<hr />
						<p
							className='text-base text-textColor hover:font-semibold duration-150 transition-all ease-in-out'
							onClick={logout}
						>
							Sign out
						</p>
					</motion.div>
				)}
			</div>
			<div className='extraButton'>
				<button
					type='button'
					class='inline-block rounded bg-neutral-100 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-neutral-600 shadow-light-3 transition duration-150 ease-in-out hover:bg-neutral-200 hover:shadow-light-2 focus:bg-neutral-200 focus:shadow-light-2 focus:outline-none focus:ring-0 active:bg-neutral-200 active:shadow-light-2 motion-reduce:transition-none dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong'
					onClick={() => navigate(-1)}
				>
					← Back
				</button>
			</div>
		</header>
	)
}

export default Header
