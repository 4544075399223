import { useStateValue } from '../Context/StateProvider'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { Header, SearchBar } from '../components'
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom'
import {
	Button,
	Dialog,
	DialogBody,
	DialogFooter,
	DialogHeader,
	Progress,
	Typography,
} from '@material-tailwind/react'
import { checkDups, killDups, refreshCategory } from '../api'

const AdminDashboard = () => {
	const navigate = useNavigate()
	const [{ user }, dispatch] = useStateValue()

	useEffect(() => {
		if (!user.role || user.role !== 'ADMIN') {
			navigate('/Releases?page=1')
		}
	}, [])

	const [open, setOpen] = useState(false)
	const [data, setData] = useState()

	const handleOpen = data => {
		setData(data)
		setOpen(!open)
	}

	const handleCheckDups = data => {
		checkDups(data.title)
			.then(res => {
				console.log(res)
				alert(
					res.data.data.map(d => `${d.count} дубля с tittle:${d._id.name}\n`)
				)
			})
			.catch(e => console.log(e))
	}

	const handleRefreshCategory = data => {
		refreshCategory(data.title)
			.then(res => {
				console.log(res)
			})
			.catch(e => {
				console.log()
			})
	}

	const handleKillDups = data => {
		killDups(data.title)
			.then(res => {
				console.log(res)
				alert('Готово')
			})
			.catch(e => console.log(e))
	}

	const sourceData = [
		{ title: 'Releases' },
		{ title: 'DJ Charts & Albums' },
		{ title: 'Mash-Up' },
		{ title: 'Top 100' },
		{ title: 'Мероприятия' },
		{ title: 'Russian Charts' },
		{ title: 'DJ Russian Charts' },
		{ title: 'Wav' },
		{ title: 'Bandcamp & Vinyl' },
	]

	////////////
	return (
		<div className='w-full h-auto flex flex-col items-center justify-center bg-primary'>
			<Header />
			<div>
				<span>
					{user?.name}
					<br />
				</span>
				<span>{user?.role}</span>
			</div>
			<div className='flex justify-around w-full px-20 mt-24 admin-container'>
				<div className='flex flex-col'>
					<span>
						<b>Обновление категорий</b>
					</span>
					<div
						className='admin-dash-buttons-ref flex flex-col'
						style={{ color: 'black' }}
					>
						{sourceData.map(data => (
							<label>
								<Button
									onClick={() => {
										const mutData = data
										mutData.refresh = true
										handleOpen(mutData)
									}}
									className='text-textColor'
								>
									{data.title}
								</Button>
							</label>
						))}
					</div>
				</div>
				<div className='flex flex-col'>
					<span>
						<b>Проверка дублей</b>
					</span>
					<div
						className='admin-dash-buttons-ref flex flex-col'
						style={{ color: 'black' }}
					>
						{sourceData.map(data => (
							<label key={data.title}>
								<Button
									onClick={() => {
										const mutData = data
										mutData.refresh = false
										handleOpen(mutData)
									}}
									className='text-textColor'
								>
									{data.title}
								</Button>
								<Button
									key={data.title}
									className={'admin_extra_button'}
									color='red'
									onClick={() => handleKillDups(data)}
								>
									Удалить дубли
								</Button>
							</label>
						))}
					</div>
				</div>
			</div>
			{open ? (
				<div
					className='absolute w-[100%] h-[100vh] flex justify-center items-center'
					style={{ backdropFilter: 'blur(2px)' }}
				>
					{' '}
					<div
						className='flex bg-white w-[30%] h-[20vh] items-center flex-col justify-center'
						style={{ borderRadius: '20px' }}
					>
						<h1>Вы уверены?</h1>
						<span>
							Что хотите {data.refresh ? 'обновить' : 'проверить на дубли'}{' '}
							категорию = {data.title}
						</span>
						<div className='flex flex-row w-64 justify-around mt-10 '>
							<Button color='red' variant='outlined' onClick={handleOpen}>
								ЗАКРЫТЬ
							</Button>{' '}
							<Button
								color='green'
								variant='outlined'
								onClick={() => {
									data.refresh
										? handleRefreshCategory(data)
										: handleCheckDups(data)
								}}
							>
								ДА!
							</Button>
						</div>
					</div>
				</div>
			) : (
				''
			)}
		</div>
	)
}

export default AdminDashboard
